exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-about-tsx": () => import("./../../../src/templates/about/about.tsx" /* webpackChunkName: "component---src-templates-about-about-tsx" */),
  "component---src-templates-index-page-index-page-tsx": () => import("./../../../src/templates/indexPage/indexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-index-page-tsx" */),
  "component---src-templates-inventory-coverage-ratio-province-province-tsx": () => import("./../../../src/templates/inventory/coverageRatio/province/province.tsx" /* webpackChunkName: "component---src-templates-inventory-coverage-ratio-province-province-tsx" */),
  "component---src-templates-inventory-coverage-ratio-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/inventory/coverageRatio/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-inventory-coverage-ratio-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-inventory-risk-distribution-province-province-tsx": () => import("./../../../src/templates/inventory/riskDistribution/province/province.tsx" /* webpackChunkName: "component---src-templates-inventory-risk-distribution-province-province-tsx" */),
  "component---src-templates-inventory-risk-distribution-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/inventory/riskDistribution/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-inventory-risk-distribution-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-overview-flanders-flanders-tsx": () => import("./../../../src/templates/overview/flanders/flanders.tsx" /* webpackChunkName: "component---src-templates-overview-flanders-flanders-tsx" */),
  "component---src-templates-overview-municipality-municipality-tsx": () => import("./../../../src/templates/overview/municipality/municipality.tsx" /* webpackChunkName: "component---src-templates-overview-municipality-municipality-tsx" */),
  "component---src-templates-state-province-province-tsx": () => import("./../../../src/templates/state/province/province.tsx" /* webpackChunkName: "component---src-templates-state-province-province-tsx" */),
  "component---src-templates-state-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/state/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-state-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-targets-area-coverage-province-province-tsx": () => import("./../../../src/templates/targets/areaCoverage/province/province.tsx" /* webpackChunkName: "component---src-templates-targets-area-coverage-province-province-tsx" */),
  "component---src-templates-targets-area-coverage-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/targets/areaCoverage/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-targets-area-coverage-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-targets-follow-up-inspection-province-province-tsx": () => import("./../../../src/templates/targets/followUpInspection/province/province.tsx" /* webpackChunkName: "component---src-templates-targets-follow-up-inspection-province-province-tsx" */),
  "component---src-templates-targets-follow-up-inspection-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/targets/followUpInspection/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-targets-follow-up-inspection-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-targets-risky-network-nodes-province-province-tsx": () => import("./../../../src/templates/targets/riskyNetworkNodes/province/province.tsx" /* webpackChunkName: "component---src-templates-targets-risky-network-nodes-province-province-tsx" */),
  "component---src-templates-targets-risky-network-nodes-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/targets/riskyNetworkNodes/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-targets-risky-network-nodes-sewer-manager-sewer-manager-tsx" */),
  "component---src-templates-targets-top-critical-province-province-tsx": () => import("./../../../src/templates/targets/topCritical/province/province.tsx" /* webpackChunkName: "component---src-templates-targets-top-critical-province-province-tsx" */),
  "component---src-templates-targets-top-critical-sewer-manager-sewer-manager-tsx": () => import("./../../../src/templates/targets/topCritical/sewerManager/sewerManager.tsx" /* webpackChunkName: "component---src-templates-targets-top-critical-sewer-manager-sewer-manager-tsx" */)
}

