import 'react-tooltip/dist/react-tooltip.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './src/styles/main.scss';

import { GatsbyBrowser, ShouldUpdateScrollArgs } from 'gatsby';
import React from 'react';
import { QueryParamProvider } from 'use-query-params';

import { GatsbyAdapter } from './src/adapters/GatsbyAdapter';
import { PageLayout } from './src/layouts';
import { PageContext } from './src/libs/shared/types';

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}: ShouldUpdateScrollArgs): boolean | [number, number] | string => {
	if (location?.state?.disableScrollUpdate) {
		return false;
	}

	return getSavedScrollPosition(location) || [0, 0];
};

export const wrapPageElement: GatsbyBrowser<
	unknown,
	PageContext
>['wrapPageElement'] = ({ element, props }) => (
	<QueryParamProvider adapter={GatsbyAdapter}>
		<PageLayout pageProps={props}>{element}</PageLayout>
	</QueryParamProvider>
);
