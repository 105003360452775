import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';
import { useState } from 'react';
import {
	PartialLocation,
	QueryParamAdapter,
	QueryParamAdapterComponent,
} from 'use-query-params';

const makeAdapter = (): QueryParamAdapter => {
	return {
		replace(location: PartialLocation) {
			return navigate(location.search || '?', {
				replace: true,
				state: {
					...location,
					disableScrollUpdate: true,
				},
			});
		},
		push(location: PartialLocation) {
			return navigate(location.search || '?', {
				replace: false,
				state: {
					...location,
					disableScrollUpdate: true,
				},
			});
		},
		get location() {
			return globalHistory.location as PartialLocation;
		},
	};
};

export const GatsbyAdapter: QueryParamAdapterComponent = ({ children }) => {
	const [adapter] = useState(makeAdapter);
	return children(adapter);
};
