import { Link } from 'gatsby-link';
import React from 'react';

import { NAVIGATION } from '../../const';
import { getNavigationTo } from '../../utils';

import * as pageUnavailableStyles from './PageUnavailable.module.scss';
import { PageUnavailableTypes } from './PageUnavailable.types';

export const PageUnavailable: React.FC<PageUnavailableTypes> = () => {
	return (
		<>
			<div className="lg:hidden">
				<div className={pageUnavailableStyles.container}>
					<div className="absolute top-[50px] right-0">
						<svg
							width="200"
							height="200"
							viewBox="0 0 200 200"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<circle
								cx="200"
								cy="-1.3033e-05"
								r="160"
								transform="rotate(180 200 -1.3033e-05)"
								stroke="#5886FF"
								strokeWidth="3"
							/>
							<circle
								cx="200"
								cy="1.31134e-05"
								r="120"
								transform="rotate(180 200 1.31134e-05)"
								stroke="#1DE3BA"
								strokeWidth="3"
							/>
						</svg>
					</div>

					<div className="absolute bottom-0 left-0">
						<svg
							width="200"
							height="200"
							viewBox="0 0 200 200"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<circle cy="200" r="160" stroke="#5886FF" strokeWidth="3" />
							<circle cy="200" r="120" stroke="#1DE3BA" strokeWidth="3" />
						</svg>
					</div>

					<h2 className={pageUnavailableStyles.title}>
						Om deze pagina te bekijken heb je een breder scherm nodig.
					</h2>
					<p>Pagina’s beschikbaar op jouw toestel</p>
					<ul className={pageUnavailableStyles.list}>
						<li className={pageUnavailableStyles.list__listItem}>
							<Link
								className={pageUnavailableStyles.list__listItemLink}
								to={getNavigationTo(NAVIGATION.dashboard)}>
								{NAVIGATION.dashboard.menu?.title}
							</Link>
						</li>
						<li className={pageUnavailableStyles.list__listItem}>
							<Link
								className={pageUnavailableStyles.list__listItemLink}
								to={getNavigationTo(NAVIGATION.about)}>
								{NAVIGATION.about.menu?.title}
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};
