import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clsx } from 'clsx';
import React, { ReactElement } from 'react';
import {
	components,
	DropdownIndicatorProps,
	default as ReactSelect,
} from 'react-select';

import * as SelectStyles from './Select.module.scss';
import { SelectOption, SelectProps } from './Select.types';

export const Select: React.FC<SelectProps> = ({
	value,
	options,
	controlClassName,
	menuClassName,
	onChange = () => {
		// this is intended
	},
	ariaLabel,
}): ReactElement => {
	const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, false>) => {
		return (
			<components.DropdownIndicator
				{...props}
				className={SelectStyles.selectDropdownIndicatorWrapper}>
				<KeyboardArrowDownIcon
					className={SelectStyles.selectDropdownIndicator}></KeyboardArrowDownIcon>
			</components.DropdownIndicator>
		);
	};

	return (
		<div className={SelectStyles.selectContainer}>
			<ReactSelect
				isMulti={false}
				options={options}
				value={value}
				isSearchable={false}
				classNames={{
					control: (state) => {
						return clsx(
							state.isFocused
								? SelectStyles.selectControlFocused
								: SelectStyles.selectControl,
							controlClassName,
						);
					},
					singleValue: () => SelectStyles.selectSingleValue,
					option: (state) => {
						if (state.isSelected) {
							return clsx(
								SelectStyles.selectOption,
								SelectStyles.selectOptionSelected,
							);
						}

						if (state.isFocused) {
							return clsx(
								SelectStyles.selectOption,
								SelectStyles.selectOptionFocused,
							);
						}

						return SelectStyles.selectOption;
					},
					menu: () => clsx(SelectStyles.selectMenu, menuClassName),
				}}
				components={{
					IndicatorSeparator: () => null,
					Placeholder: () => null,
					DropdownIndicator,
				}}
				onChange={(option) => onChange(option)}
				aria-label={ariaLabel}></ReactSelect>
		</div>
	);
};
