import clsx from 'clsx';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { find, propEq } from 'ramda';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';

import { BackgroundCircleType, switchMenuOptions } from './PageLayout.const';
import {
	LayoutBackgroundConfig,
	PageDataType,
	PageLayoutProps,
} from './PageLayout.types';
import * as pageLayoutStyles from './pageLayout.module.scss';

import { Header, HeaderActions, HeaderSwitchMenu } from '@shared/components/Header';
import { PageUnavailable } from '@shared/components/PageUnavailable';
import { Search, SearchOption } from '@shared/components/Search';
import { Select, SelectOption } from '@shared/components/Select';

const pageContainerId = 'page-container';
const outerContainerId = 'outer-container';

const createCircleClassNames = (
	circleType: BackgroundCircleType,
	canShowOnMobileScreens: boolean,
	config: LayoutBackgroundConfig,
	mobile = false,
): string => {
	const commonClassNames = clsx(
		pageLayoutStyles.circle,
		pageLayoutStyles[circleType],
		config[circleType].zIndex,
	);
	if (mobile) {
		return clsx(
			// Hide mobile circle on large screens
			'lg:hidden',
			commonClassNames,
			{
				block: canShowOnMobileScreens && config[circleType].isVisible,
				hidden: !canShowOnMobileScreens || !config[circleType].isVisible,
			},
		);
	}

	return clsx(
		// Hide desktop circles by default
		'hidden',
		commonClassNames,
		{
			// Show desktop circle on large screens
			'lg:block': config[circleType].isVisible,
		},
	);
};

export const PageLayout: React.FC<PageLayoutProps> = ({
	children,
	pageProps,
}): ReactElement => {
	const {
		municipality,
		hasHeaderShadow = false,
		hasSwitchMenu = false,
		canShowOnMobileScreens,
		layoutBackground,
	} = pageProps.pageContext;

	const data: PageDataType = useStaticQuery(graphql`
		query PageLayoutQuery {
			allMunicipality(sort: { municipality: ASC }) {
				nodes {
					municipality
				}
			}
			renewalData {
				lastModificationDate
			}
		}
	`);
	const { allMunicipality, renewalData } = data;

	const layoutBackgroundConfig: LayoutBackgroundConfig = useMemo(
		() => ({
			circleTop: {
				isVisible: layoutBackground?.circleTop?.isVisible ?? false,
				zIndex: layoutBackground?.circleTop?.zIndex ?? 'z-0',
			},
			circleBottom: {
				isVisible: layoutBackground?.circleBottom?.isVisible ?? false,
				zIndex: layoutBackground?.circleBottom?.zIndex ?? 'z-0',
			},
		}),
		[layoutBackground],
	);

	const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
	const [selectedMunicipality, setSelectedMunicipality] =
		useState<SingleValue<SearchOption> | null>(null);

	const [currentSwitchValue, setCurrentSwitchValue] = useState<SelectOption>();
	useEffect(() => {
		const value: string =
			pageProps.uri.match(/(\/rioolbeheerder|\/provincie)/g)?.pop() || '';
		const switchValue: SelectOption = find(propEq('value', value))(
			switchMenuOptions,
		) as SelectOption;

		if (switchValue) {
			setCurrentSwitchValue(switchValue);
		}
	}, [pageProps]);

	const onSwitchPage = (option: SingleValue<SelectOption>) => {
		if (option && currentSwitchValue) {
			navigate(pageProps.uri.replace(currentSwitchValue.value, option.value));
		}
	};

	useEffect(() => {
		const updateValue = municipality
			? {
					label: municipality,
					value: municipality.toLowerCase(),
			  }
			: null;

		setSelectedMunicipality(updateValue);
	}, [municipality]);

	useEffect(() => {
		setSearchOptions(
			allMunicipality.nodes.map((obj) => ({
				label: obj.municipality,
				value: obj.municipality.toLowerCase(),
			})) as unknown as SearchOption[],
		);
	}, [allMunicipality.nodes]);

	const handleMunicipalitySelect = (municipality: SingleValue<SearchOption>) => {
		setSelectedMunicipality(municipality);
		const navigatePath = municipality
			? `/dashboard/${municipality?.value}`
			: '/dashboard/';

		navigate(navigatePath);
	};

	const classNameTop = createCircleClassNames(
		BackgroundCircleType.Top,
		canShowOnMobileScreens,
		layoutBackgroundConfig,
	);
	const classNameBottom = createCircleClassNames(
		BackgroundCircleType.Bottom,
		canShowOnMobileScreens,
		layoutBackgroundConfig,
	);

	const classNameMobileTop = createCircleClassNames(
		BackgroundCircleType.Top,
		canShowOnMobileScreens,
		layoutBackgroundConfig,
		true,
	);
	const classNameMobileBottom = createCircleClassNames(
		BackgroundCircleType.Bottom,
		canShowOnMobileScreens,
		layoutBackgroundConfig,
		true,
	);

	return (
		<div className="relative min-h-screen bg-grey-xlight" id={outerContainerId}>
			<div className={classNameTop}>
				<svg
					width="200"
					height="200"
					viewBox="0 0 200 200"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="200"
						cy="-1.3033e-05"
						r="198.5"
						transform="rotate(180 200 -1.3033e-05)"
						stroke="#5886FF"
						strokeWidth="3"
					/>
					<circle
						cx="200"
						cy="1.31134e-05"
						r="148.5"
						transform="rotate(180 200 1.31134e-05)"
						stroke="#1DE3BA"
						strokeWidth="3"
					/>
				</svg>
			</div>
			<div className={classNameMobileTop}>
				<svg
					width="200"
					height="200"
					viewBox="0 0 200 200"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="200"
						cy="-1.3033e-05"
						r="160"
						transform="rotate(180 200 -1.3033e-05)"
						stroke="#5886FF"
						strokeWidth="3"
					/>
					<circle
						cx="200"
						cy="1.31134e-05"
						r="120"
						transform="rotate(180 200 1.31134e-05)"
						stroke="#1DE3BA"
						strokeWidth="3"
					/>
				</svg>
			</div>

			<div className={classNameBottom}>
				<svg
					width="200"
					height="200"
					viewBox="0 0 200 200"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle cy="200" r="198.5" stroke="#5886FF" strokeWidth="3" />
					<circle cy="200" r="148.5" stroke="#1DE3BA" strokeWidth="3" />
				</svg>
			</div>
			<div className={classNameMobileBottom}>
				<svg
					width="200"
					height="200"
					viewBox="0 0 200 200"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle cy="200" r="160" stroke="#5886FF" strokeWidth="3" />
					<circle cy="200" r="120" stroke="#1DE3BA" strokeWidth="3" />
				</svg>
			</div>
			<div className="z-1 relative">
				<Header
					lastModificationDate={renewalData.lastModificationDate}
					pageWrapId={pageContainerId}
					outerContainerId={outerContainerId}
					hasShadow={hasHeaderShadow}>
					{hasSwitchMenu && currentSwitchValue ? (
						<HeaderSwitchMenu>
							<Select
								ariaLabel="Categorie voor statistieken selecteren"
								options={switchMenuOptions}
								value={currentSwitchValue}
								onChange={onSwitchPage}
								menuClassName="min-w-[200px]"
							/>
						</HeaderSwitchMenu>
					) : (
						<></>
					)}
					<HeaderActions>
						<Search
							placeholder="Gemeente zoeken"
							ariaLabel="Gemeente zoeken"
							value={selectedMunicipality}
							options={searchOptions}
							onChange={handleMunicipalitySelect}
							containerClassName="min-w-[200px]"
						/>
					</HeaderActions>
				</Header>
				<main id={pageContainerId} className="lg:pt-2xl">
					{canShowOnMobileScreens ? (
						<>{children}</>
					) : (
						<>
							<div className="hidden lg:block">{children}</div>
							<PageUnavailable />
						</>
					)}
				</main>
			</div>
		</div>
	);
};
