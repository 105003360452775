/** @type {import('tailwindcss').Config} */
module.exports = {
	content: [
		'./src/pages/**/*.{js,jsx,ts,tsx}',
		'./src/components/**/*.{js,jsx,ts,tsx}',
		'./src/layouts/**/*.{js,jsx,ts,tsx}',
		'./src/templates/**/*.{js,jsx,ts,tsx}*',
		'./src/libs/**/*.{js,jsx,ts,tsx}*'
	],
	theme: {
		fontFamily: {
			poppins: 'Poppins, sans-serif',
			inter: 'Inter, sans-serif',
			sansPro: 'Source Sans Pro, sans-serif',
		},
		screens: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
			'2xl': '1536px',
		},
		colors: {
			white: {
				DEFAULT: '#ffffff',
			},
			black: {
				DEFAULT: '#000000',
			},
			blue: {
				xdark: '#171152',
				dark: '#3A68E1',
				DEFAULT: '#5886FF',
				light: '#9BB6FF',
				xlight: '#DEE7FF',
			},
			green: {
				dark: '#00A77E',
				DEFAULT: '#1DE3BA',
				light: '#AFF6E8',
				xlight: '#E4FAF5',
				xxlight: '#C6F8ED',
			},
			yellow: {
				DEFAULT: '#DFE31D',
			},
			orange: {
				DEFAULT: '#E3A01D',
				light: '#ffedce',
				xlight: '#fffaf0',
			},
			red: {
				dark: '#890000',
				DEFAULT: '#E31D1D',
				light: '#FDDEDE',
				xlight: '#FFF3F3',
			},
			purple: {
				DEFAULT: '#A41DE3',
			},
			grey: {
				xdark: '#5D6684',
				dark: '#838BA3',
				DEFAULT: '#C1C7D7',
				light: '#E4E5ED',
				xlight: '#F2F5F9',
			},
		},
		spacing: {
			0: '0rem',
			'4xs': '0.4rem',
			'3xs': '0.8rem',
			'2xs': '1.2rem',
			xs: '1.6rem',
			sm: '2.4rem',
			md: '3.2rem',
			lg: '4.8rem',
			xl: '6.4rem',
			'2xl': '8rem',
		},
		borderRadius: {
			sm: '4px',
			DEFAULT: '8px',
			'2xl': '40px',
			full: '100%',
		},
		boxShadow: {
			dark: '0px 5px 25px rgba(0, 0, 0, 0.10)',
			DEFAULT: '0px 5px 25px rgba(0, 0, 0, 0.05)',
		},
		fontSize: {
			xs: '1.2rem',
			sm: '1.4rem',
			base: '1.6rem',
			lg: '1.8rem',
			xl: '2rem',
			'2xl': '2.2rem',
			'3xl': '2.4rem',
			'4xl': '2.8rem',
			'5xl': '3.2rem',
			'6xl': '4rem',
		},
		extend: {},
	},
	plugins: [],
};
