import { stringify } from 'querystringify';
import { clone, equals, lensProp, omit, set } from 'ramda';
import { encodeQueryParams } from 'use-query-params';

import { NAVIGATION } from '../../const';
import { NavigationItem, NavigationItems } from '../../types';

import { LocationOptions } from './navigation.util.types';

export const getNavigationTo = (
	item: NavigationItem,
	options?: LocationOptions,
): string => {
	if (item.menu?.disabled) {
		return '';
	}

	let location = recursiveGetPath(item, NAVIGATION);

	if (!location) {
		return '';
	}

	if (options?.paramConfigMap && options?.query) {
		location += `?${stringify(
			encodeQueryParams(options.paramConfigMap, options.query),
		)}`;
	}

	if (options?.anchor) {
		location += `#${options.anchor}`;
	}

	return location;
};

export const getSubmenuItems = (item: NavigationItem): NavigationItem[] => {
	if (item.submenu) {
		return Object.values(item.submenu);
	}
	return [];
};

export const getAllNavigationItems = (): NavigationItem[] => {
	return recursiveGetNavigationItems(NAVIGATION);
};

const partialNavItemEq = (a: NavigationItem, b: NavigationItem) =>
	equals(omit(['submenu'], a), omit(['submenu'], b));

const recursiveGetPath = (
	item: NavigationItem,
	menu: NavigationItems,
	path = '',
): string | undefined => {
	for (const value of Object.values(menu)) {
		if (partialNavItemEq(value, item)) {
			return `${path}/${value.to}`;
		} else if (value.submenu) {
			const submenuPath = recursiveGetPath(
				item,
				value.submenu,
				`${path}/${value.to}`,
			);
			if (typeof submenuPath === 'string') {
				return submenuPath;
			}
		}
	}
};

const recursiveGetNavigationItems = (
	menu: NavigationItems,
	items: NavigationItem[] = [],
) => {
	Object.values(menu).forEach((item) => {
		items.push(set(lensProp('submenu'), {}, clone(item)));
		if (item.submenu) {
			items = recursiveGetNavigationItems(item.submenu, items);
		}
	});
	return items;
};
