import { SelectOption } from '@shared/components/Select';

export const switchMenuOptions: SelectOption[] = [
	{
		label: 'Per provincie',
		value: '/provincie',
	},
	{
		label: 'Per rioolbeheerder',
		value: '/rioolbeheerder',
	},
];

export enum BackgroundCircleType {
	Top = 'circleTop',
	Bottom = 'circleBottom',
}
