import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { clsx } from 'clsx';
import { Link } from 'gatsby';
import React, { ReactElement, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';

import { NAVIGATION } from '../../const';
import { useSlot } from '../../hooks/useSlot';
import { getNavigationTo } from '../../utils';
import { DateUpdated } from '../DateUpdated/DateUpdated';

import * as headerStyles from './Header.module.scss';
import { HeaderActions, HeaderSwitchMenu } from './Header.slots';
import { HeaderProps } from './Header.types';

export const Header: React.FC<HeaderProps> = ({
	pageWrapId,
	outerContainerId,
	hasShadow = true,
	children,
	lastModificationDate,
}): ReactElement => {
	const actionsSlot = useSlot(HeaderActions, children);
	const switchMenuSlot = useSlot(HeaderSwitchMenu, children);
	const [menuIsOpen, setMenuOpen] = useState<boolean>(false);

	const headerMobileClassName = clsx(
		headerStyles.header,
		headerStyles.headerMobile,
		hasShadow && headerStyles.headerShadow,
	);

	const headerDesktopClassName = clsx(
		headerStyles.header,
		headerStyles.headerDesktop,
		hasShadow && headerStyles.headerShadow,
	);

	const toggleMenu = (): void => {
		setMenuOpen(!menuIsOpen);
	};

	const renderDateUpdated = (): ReactElement => (
		<DateUpdated
			className={headerStyles.headerDateUpdated}
			date={new Date(lastModificationDate)}
		/>
	);
	const renderActions = (element: ReactElement | ReactElement[]): ReactElement => (
		<div className={headerStyles.headerActions}>{element}</div>
	);
	const renderSwitchMenu = (
		element: ReactElement | ReactElement[],
	): ReactElement => <div className={headerStyles.headerSwitchMenu}>{element}</div>;

	const renderMobileHeader = (): ReactElement => (
		<div className={headerStyles.headerMobileWrapper}>
			<header data-testid="header-mobile" className={headerMobileClassName}>
				<div className={headerStyles.headerMobileMain}>
					<div>
						<div className={headerStyles.header__logo}>Status Tool</div>
					</div>
					<button
						className={headerStyles.header__menuIconBtn}
						onClick={toggleMenu}
						aria-label="Toggle hoofdmenu">
						{menuIsOpen ? (
							<CloseIcon className={headerStyles.header__menuIcon} />
						) : (
							<MenuIcon className={headerStyles.header__menuIcon} />
						)}
					</button>
				</div>
				<div className={headerStyles.headerMobileDate}>{renderDateUpdated()}</div>
			</header>
			{actionsSlot && renderActions(actionsSlot)}
		</div>
	);

	const renderDesktopHeader = (): ReactElement => (
		<header data-testid="header-desktop" className={headerDesktopClassName}>
			<div className="flex items-center self-stretch">
				<div className={headerStyles.header__logo}>Status Tool</div>
				<nav className={headerStyles.desktopMenu__wrapper}>
					<ul className={headerStyles.desktopMenu__list}>
						<li className={headerStyles.desktopMenu__listItem}>
							<Link
								className={headerStyles.desktopMenu__listItemLink}
								activeClassName={headerStyles.active}
								partiallyActive
								to={getNavigationTo(NAVIGATION.dashboard)}>
								{NAVIGATION.dashboard.menu?.title}
							</Link>
						</li>
						<li className={headerStyles.desktopMenu__listItem}>
							<Link
								className={headerStyles.desktopMenu__listItemLink}
								activeClassName={headerStyles.active}
								partiallyActive
								to={getNavigationTo(NAVIGATION.inventory)}>
								{NAVIGATION.inventory.menu?.title}
							</Link>
						</li>
						<li className={headerStyles.desktopMenu__listItem}>
							<Link
								className={headerStyles.desktopMenu__listItemLink}
								activeClassName={headerStyles.active}
								partiallyActive
								to={getNavigationTo(NAVIGATION.targets)}>
								{NAVIGATION.targets.menu?.title}
							</Link>
						</li>
						<li className={headerStyles.desktopMenu__listItem}>
							<Link
								className={headerStyles.desktopMenu__listItemLink}
								activeClassName={headerStyles.active}
								partiallyActive
								to={getNavigationTo(NAVIGATION.state)}>
								{NAVIGATION.state.menu?.title}
							</Link>
						</li>
						<li className={headerStyles.desktopMenu__listItem}>
							<Link
								className={headerStyles.desktopMenu__listItemLink}
								activeClassName={headerStyles.active}
								partiallyActive
								to={getNavigationTo(NAVIGATION.about)}>
								{NAVIGATION.about.menu?.title}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			{renderDateUpdated()}
			{switchMenuSlot && renderSwitchMenu(switchMenuSlot)}
			{actionsSlot && renderActions(actionsSlot)}
		</header>
	);

	const renderMobileMenu = (): ReactElement => (
		<Menu
			right
			disableOverlayClick
			noOverlay
			className={headerStyles.mobileMenu}
			menuClassName={headerStyles.mobileMenu__wrapper}
			pageWrapId={pageWrapId}
			outerContainerId={outerContainerId}
			customBurgerIcon={false}
			customCrossIcon={false}
			isOpen={menuIsOpen}
			width={'100%'}>
			<ul className={headerStyles.mobileMenu__list}>
				<li className={headerStyles.mobileMenu__listItem}>
					<Link
						className={headerStyles.mobileMenu__listItemLink}
						activeClassName={headerStyles.active}
						partiallyActive
						to={getNavigationTo(NAVIGATION.dashboard)}
						onClick={() => setMenuOpen(false)}>
						{NAVIGATION.dashboard.menu?.title}
					</Link>
				</li>
				<li className={headerStyles.mobileMenu__listItem}>
					<Link
						className={headerStyles.mobileMenu__listItemLink}
						activeClassName={headerStyles.active}
						partiallyActive
						to={getNavigationTo(NAVIGATION.about)}
						onClick={() => setMenuOpen(false)}>
						{NAVIGATION.about.menu?.title}
					</Link>
				</li>
			</ul>
			<h2 className={headerStyles.mobileMenu__text}>
				Wil je nog meer details raadplegen? Bekijk deze website op een breder scherm
			</h2>
			<div className="absolute bottom-0 left-0 z-30">
				<svg
					width="200"
					height="200"
					viewBox="0 0 200 200"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle cy="200" r="160" stroke="#5886FF" strokeWidth="3" />
					<circle cy="200" r="120" stroke="#1DE3BA" strokeWidth="3" />
				</svg>
			</div>
		</Menu>
	);

	return (
		<>
			{renderMobileHeader()}
			{renderMobileMenu()}
			{renderDesktopHeader()}
		</>
	);
};
