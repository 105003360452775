import { BooleanParam } from 'use-query-params';

import { NavigationItems } from '../types';

export const FILTERS = {
	municipalityFilter: {
		name: 'municipalityFilter',
		type: BooleanParam,
	},
};

export const NAVIGATION: NavigationItems = {
	root: {
		to: '',
		componentPath: 'src/templates/indexPage/indexPage.tsx',
		redirectTo: '/dashboard',
		context: {
			metadata: {
				title: 'Status tool',
				description: 'Beschrijving Status tool',
			},
		},
		submenu: {},
	},
	dashboard: {
		title: 'Overzicht Vlaanderen',
		to: 'dashboard',
		componentPath: 'src/templates/overview/flanders/flanders.tsx',
		menu: {
			title: 'Overzicht',
		},
		context: {
			metadata: {
				title: 'Dashboard Vlaanderen',
				description: 'Status van het rioolinspectieplan in Vlaanderen',
			},
			layoutBackground: {
				circleTop: {
					isVisible: true,
				},
				circleBottom: {
					isVisible: true,
				},
			},
		},
		submenu: {},
	},
	inventory: {
		to: 'inventaris',
		componentPath: 'src/templates/indexPage/indexPage.tsx',
		redirectTo: '/inventaris/provincie/dekkingsgraad',
		menu: {
			title: 'Inventaris',
		},
		context: {
			metadata: {
				title: 'Inventaris – dekkingsgraad per provincie',
				description:
					'Volledigheid van het rioolstelsel in het kader van het rioolinspectieplan',
			},
		},
		submenu: {
			province: {
				to: 'provincie',
				componentPath: 'src/templates/indexPage/indexPage.tsx',
				redirectTo: '/inventaris/provincie/dekkingsgraad',
				menu: {
					title: 'Per provincie',
				},
				context: {
					metadata: {
						title: 'Inventaris – dekkingsgraad per provincie',
						description:
							'Volledigheid van het rioolstelsel in het kader van het rioolinspectieplan',
					},
				},
				submenu: {
					coverageRatio: {
						title: 'Inventaris per provincie',
						to: 'dekkingsgraad',
						componentPath:
							'src/templates/inventory/coverageRatio/province/province.tsx',
						menu: {
							title: 'Dekkingsgraad',
						},
						context: {
							description:
								"Bekijk hoe volledig het stelsel is en wat de risico's zijn.",
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Inventaris – dekkingsgraad per provincie',
								description:
									'Volledigheid van het rioolstelsel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					riskDistribution: {
						title: 'Inventaris per provincie',
						to: 'risicoverdeling',
						componentPath:
							'src/templates/inventory/riskDistribution/province/province.tsx',
						menu: {
							title: 'Risicoverdeling',
						},
						context: {
							description:
								"Bekijk hoe volledig het stelsel is en wat de risico's zijn.",
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Inventaris – risicoverdeling per provincie',
								description:
									'Risicoverdeling van het rioolstelsel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
				},
			},
			sewerManager: {
				to: 'rioolbeheerder',
				componentPath: 'src/templates/indexPage/indexPage.tsx',
				redirectTo: '/inventaris/rioolbeheerder/dekkingsgraad',
				menu: {
					title: 'Per rioolbeheerder',
				},
				context: {
					metadata: {
						title: 'Inventaris – dekkingsgraad per rioolbeheerder',
						description:
							'Volledigheid van het rioolstelsel in het kader van het rioolinspectieplan',
					},
				},
				submenu: {
					coverageRatio: {
						title: 'Inventaris per rioolbeheerder',
						to: 'dekkingsgraad',
						componentPath:
							'src/templates/inventory/coverageRatio/sewerManager/sewerManager.tsx',
						menu: {
							title: 'Dekkingsgraad',
						},
						context: {
							description:
								"Bekijk hoe volledig het stelsel is en wat de risico's zijn.",
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Inventaris – dekkingsgraad per rioolbeheerder',
								description:
									'Volledigheid van het rioolstelsel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					riskDistribution: {
						title: 'Inventaris per rioolbeheerder',
						to: 'risicoverdeling',
						componentPath:
							'src/templates/inventory/riskDistribution/sewerManager/sewerManager.tsx',
						menu: { title: 'Risicoverdeling' },
						context: {
							description:
								"Bekijk hoe volledig het stelsel is en wat de risico's zijn.",
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Inventaris – risicoverdeling per rioolbeheerder',
								description:
									'Risicoverdeling van het rioolstelsel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
				},
			},
		},
	},
	targets: {
		to: 'streefdoelen',
		componentPath: 'src/templates/indexPage/indexPage.tsx',
		redirectTo: '/streefdoelen/provincie/gebiedsdekkend',
		menu: {
			title: 'Streefdoelen',
		},
		context: {
			metadata: {
				title: 'Streefdoel – topkritische leidingen per provincie',
				description:
					'Status van inspectie van topkritische leidingen in het kader van het rioolinspectieplan',
			},
		},
		submenu: {
			province: {
				to: 'provincie',
				componentPath: 'src/templates/indexPage/indexPage.tsx',
				redirectTo: '/streefdoelen/provincie/gebiedsdekkend',
				menu: {
					title: 'Per provincie',
				},
				context: {
					metadata: {
						title: 'Streefdoel – topkritische leidingen per provincie',
						description:
							'Status van inspectie van topkritische leidingen in het kader van het rioolinspectieplan',
					},
				},
				submenu: {
					areaCoverage: {
						title: 'Streefdoelen per provincie',
						to: 'gebiedsdekkend',
						componentPath:
							'src/templates/targets/areaCoverage/province/province.tsx',
						menu: {
							title: 'Gebiedsdekkend',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – gebiedsdekking per provincie',
								description:
									'Status van het gebiedsdekkend streefdoel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					topcriticalPipes: {
						title: 'Streefdoelen per provincie',
						to: 'topkritische-leidingen',
						componentPath: 'src/templates/targets/topCritical/province/province.tsx',
						menu: {
							title: 'Topkritische leidingen',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – topkritische leidingen per provincie',
								description:
									'Status van inspectie van topkritische leidingen in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					riskyNetworkNodes: {
						title: 'Streefdoelen per provincie',
						to: 'risicovolle-netwerkknopen',
						componentPath:
							'src/templates/targets/riskyNetworkNodes/province/province.tsx',
						menu: {
							title: 'Risicovolle netwerkknopen',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – risicovolle netwerkknopen per provincie',
								description:
									'Status van inspectie van risicovolle netwerkknopen in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					followUpInspection: {
						title: 'Streefdoelen per provincie',
						to: 'after-inspection',
						componentPath:
							'src/templates/targets/followUpInspection/province/province.tsx',
						menu: {
							title: 'Na-inspectie',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – na inspectie per provincie',
								description:
									'Status van inspectie van leidingen in slechte toestand in het kader van het rioolinspectieplan ',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
				},
			},
			sewerManager: {
				to: 'rioolbeheerder',
				componentPath: 'src/templates/indexPage/indexPage.tsx',
				redirectTo: '/streefdoelen/rioolbeheerder/gebiedsdekkend',
				menu: {
					title: 'Per rioolbeheerder',
				},
				context: {
					metadata: {
						title: 'Streefdoel – topkritische leidingen per rioolbeheerder',
						description:
							'Status van inspectie van topkritische leidingen in het kader van het rioolinspectieplan',
					},
				},
				submenu: {
					areaCoverage: {
						title: 'Streefdoelen per rioolbeheerder',
						to: 'gebiedsdekkend',
						componentPath:
							'src/templates/targets/areaCoverage/sewerManager/sewerManager.tsx',
						menu: {
							title: 'Gebiedsdekkend',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – gebiedsdekking per rioolbeheerder',
								description:
									'Status van het gebiedsdekkend streefdoel in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					topcriticalPipes: {
						title: 'Streefdoelen per rioolbeheerder',
						to: 'topkritische-leidingen',
						componentPath:
							'src/templates/targets/topCritical/sewerManager/sewerManager.tsx',
						menu: {
							title: 'Topkritische leidingen',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – topkritische leidingen per rioolbeheerder',
								description:
									'Status van inspectie van topkritische leidingen in het kader van het rioolinspectieplan ',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					riskyNetworkNodes: {
						title: 'Streefdoelen per rioolbeheerder',
						to: 'risicovolle-netwerkknopen',
						componentPath:
							'src/templates/targets/riskyNetworkNodes/sewerManager/sewerManager.tsx',
						menu: {
							title: 'Risicovolle netwerkknopen',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – risicovolle netwerkknopen per rioolbeheerder',
								description:
									'Status van inspectie van risicovolle netwerkknopen in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
					followUpInspection: {
						title: 'Streefdoelen per rioolbeheerder',
						to: 'after-inspection',
						componentPath:
							'src/templates/targets/followUpInspection/sewerManager/sewerManager.tsx',
						menu: {
							title: 'Na-inspectie',
						},
						context: {
							description: 'Volg hier de evolutie t.o.v. de minimum voorwaarden',
							hasHeaderShadow: false,
							hasSwitchMenu: true,
							metadata: {
								title: 'Streefdoel – na inspectie per rioolbeheerder',
								description:
									'Status van inspectie van leidingen in slechte toestand in het kader van het rioolinspectieplan',
							},
							canShowOnMobileScreens: false,
						},
						submenu: {},
					},
				},
			},
		},
	},
	state: {
		to: 'toestand',
		componentPath: 'src/templates/indexPage/indexPage.tsx',
		redirectTo: '/toestand/provincie',
		menu: {
			title: 'Toestand',
		},
		context: {
			metadata: {
				title: 'Toestand',
				description: 'Toestand',
			},
		},
		submenu: {
			province: {
				title: 'Toestand per provincie',
				to: 'provincie',
				componentPath: 'src/templates/state/province/province.tsx',
				menu: {
					title: 'Per provincie',
				},
				context: {
					description:
						'Hier kan je de structurele toestand van het stelsel opvolgen. Deze toestand is het resultaat van afgelopen inspecties.',
					hasHeaderShadow: false,
					hasSwitchMenu: true,
					metadata: {
						title: 'Structurele toestand van het rioolnet per provincie',
						description: 'Structurele toestand van het rioolnet in Vlaanderen',
					},
					canShowOnMobileScreens: false,
				},
				submenu: {},
			},
			sewerManager: {
				title: 'Toestand per rioolbeheerder',
				to: 'rioolbeheerder',
				componentPath: 'src/templates/state/sewerManager/sewerManager.tsx',
				menu: {
					title: 'Per rioolbeheerder',
				},
				context: {
					description:
						'Hier kan je de structurele toestand van het stelsel opvolgen. Deze toestand is het resultaat van afgelopen inspecties.',
					hasHeaderShadow: false,
					hasSwitchMenu: true,
					metadata: {
						title: 'Structurele toestand van het rioolnet per rioolbeheerder',
						description: 'Structurele toestand van het rioolnet in Vlaanderen',
					},
					canShowOnMobileScreens: false,
				},
				submenu: {},
			},
		},
	},
	about: {
		title: 'Over Status Tool',
		to: 'over-status-tool',
		componentPath: 'src/templates/about/about.tsx',
		menu: {
			title: 'Over Status Tool',
		},
		context: {
			description:
				'Een rioolinventaris en inspectieplan voor elke Vlaamse stad of gemeente',
			metadata: {
				title: 'Status Tool',
				description:
					'Status tool toont de evolutie van inspecties van het rioolnet in Vlaanderen alsook de resulterende structurele toestand ervan',
			},
			layoutBackground: {
				circleTop: {
					isVisible: true,
				},
				circleBottom: {
					isVisible: true,
					zIndex: 'z-10',
				},
			},
		},
		submenu: {},
	},
};
