// extracted by mini-css-extract-plugin
export var searchCloseIndicator = "Search-module--search-close-indicator--f35fd";
export var searchContainer = "Search-module--search-container--24935";
export var searchControl = "Search-module--search-control--c60c9";
export var searchIcon = "Search-module--search-icon--4900a";
export var searchInput = "Search-module--search-input--d7539";
export var searchNoOptionsMessage = "Search-module--search-no-options-message--82ba0";
export var searchOption = "Search-module--search-option--8ebd5";
export var searchOptionFocused = "Search-module--search-option-focused--1ce3a";
export var searchOptionSelected = "Search-module--search-option-selected--25a41";
export var searchPlaceholder = "Search-module--search-placeholder--90471";
export var searchPlaceholderFocused = "Search-module--search-placeholder-focused--501d9";
export var searchSingleValue = "Search-module--search-single-value--2b9c1";
export var searchValueContainer = "Search-module--search-value-container--ea4a0";