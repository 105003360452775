import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';
import React, { ReactElement, useRef } from 'react';
import Select, { ActionMeta, components, SingleValue } from 'react-select';

import * as SearchStyles from './Search.module.scss';
import { SearchOption, SearchProps } from './Search.types';

export const Search: React.FC<SearchProps> = ({
	onChange = () => {
		// this is intended
	},
	options,
	placeholder,
	noOptionsMessage = 'Geen resultaten gevonden',
	containerClassName,
	value,
	ariaLabel,
}): ReactElement => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const selectRef = useRef<any>();

	const ValueContainer: typeof components.ValueContainer = ({
		children,
		...props
	}) => {
		return (
			components.ValueContainer && (
				<components.ValueContainer {...props}>
					<SearchIcon className={SearchStyles.searchIcon}></SearchIcon>
					{children}
				</components.ValueContainer>
			)
		);
	};

	const Option: typeof components.Option = ({ children, ...props }) => {
		return (
			components.Option && (
				<components.Option {...props}>
					{children}
					<ChevronRightIcon></ChevronRightIcon>
				</components.Option>
			)
		);
	};

	const ClearIndicator: typeof components.ClearIndicator = ({ ...props }) => {
		return (
			components.ClearIndicator && (
				<components.ClearIndicator {...props}>
					<CloseIcon></CloseIcon>
				</components.ClearIndicator>
			)
		);
	};

	const handleSelectChange = (
		option: SingleValue<SearchOption> | null,
		actionMeta: ActionMeta<SearchOption>,
	) => {
		/**
		 * Blur the select field after clearing the selected values
		 * There is currently no other option to achieve this
		 */
		if (actionMeta.action === 'clear' || actionMeta.action === 'remove-value') {
			setTimeout(() => {
				selectRef.current.blur();
				onChange(option);
			}, 1);
		} else {
			onChange(option);
		}
	};

	return (
		<div className={clsx(SearchStyles.searchContainer, containerClassName)}>
			<Select
				ref={selectRef}
				classNames={{
					control: () => SearchStyles.searchControl,
					input: () => SearchStyles.searchInput,
					placeholder: (state) => {
						return state.isFocused
							? SearchStyles.searchPlaceholderFocused
							: SearchStyles.searchPlaceholder;
					},
					option: (state) => {
						if (state.isSelected) {
							return clsx(
								SearchStyles.searchOption,
								SearchStyles.searchOptionSelected,
							);
						}

						if (state.isFocused) {
							return clsx(
								SearchStyles.searchOption,
								SearchStyles.searchOptionFocused,
							);
						}

						return SearchStyles.searchOption;
					},
					valueContainer: () => SearchStyles.searchValueContainer,
					singleValue: () => SearchStyles.searchSingleValue,
					noOptionsMessage: () => SearchStyles.searchNoOptionsMessage,
					clearIndicator: () => SearchStyles.searchCloseIndicator,
				}}
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					ValueContainer,
					Option,
					ClearIndicator,
				}}
				blurInputOnSelect={true}
				backspaceRemovesValue={false}
				isClearable={true}
				isMulti={false}
				placeholder={placeholder}
				options={options}
				value={value}
				aria-label={ariaLabel}
				noOptionsMessage={() => noOptionsMessage}
				onChange={handleSelectChange}></Select>
		</div>
	);
};
