export enum Status {
	Ok = 'OK',
	Nok = 'NOK',
}

export enum Exempted {
	Yes = 'J',
	No = 'N',
}

export enum PlanningStatus {
	Ready = 'Streefdoel gehaald',
	OnTrack = 'Planning on track',
	Insufficient = 'Planning onvoldoende',
}
