import React from 'react';

import { SlotComponentProps } from '../../hooks';

export const HeaderActions: React.FC<SlotComponentProps> = ({ children }) => (
	<>{children}</>
);

export const HeaderSwitchMenu: React.FC<SlotComponentProps> = ({ children }) => (
	<>{children}</>
);
