import { Exempted, Status } from '../const/data.const';

export const ratioToPercentage = (ratio?: number): string => {
	if ((ratio ?? 0) < 0.01) {
		return ((ratio ?? 0) * 100).toLocaleString(undefined, {
			maximumFractionDigits: 1,
		});
	}
	return Math.round((ratio ?? 0) * 100).toFixed();
};

export const roundToSingleDigit = (value?: number): string => {
	if ((value ?? 0) < 1) {
		return (value ?? 0)?.toLocaleString(undefined, { maximumFractionDigits: 1 });
	}
	return Math.round(value ?? 0).toFixed();
};

export const isStatusOk = (status?: Status, exempted?: Exempted) => {
	// return false when the status is undefined
	if (!status) {
		return false;
	}

	// Return true when status is OK
	if (status === Status.Ok) {
		return true;
	}

	// When the status is not ok and the exempted prop
	// is undefined return false
	if (status === Status.Nok && !exempted) {
		return false;
	}

	// When the status is not ok and the exempted prop set to yes
	// return true
	if (status === Status.Nok && exempted === Exempted.Yes) {
		return true;
	}

	return false;
};
