// extracted by mini-css-extract-plugin
export var active = "Header-module--active--8f6dd";
export var desktopMenu__list = "Header-module--desktop-menu__list--12257";
export var desktopMenu__listItem = "Header-module--desktop-menu__list-item--fc043";
export var desktopMenu__listItemLink = "Header-module--desktop-menu__list-item-link--ed7d5";
export var desktopMenu__wrapper = "Header-module--desktop-menu__wrapper--4a19c";
export var disabled = "Header-module--disabled--81e7b";
export var header = "Header-module--header--a5774";
export var headerActions = "Header-module--header-actions--05859";
export var headerDateUpdated = "Header-module--header-date-updated--f57d0";
export var headerDesktop = "Header-module--header--desktop--0fed4";
export var headerMobile = "Header-module--header--mobile--4098a";
export var headerMobileDate = "Header-module--header--mobile-date--b4b13";
export var headerMobileMain = "Header-module--header--mobile-main--1b06b";
export var headerMobileWrapper = "Header-module--header--mobile-wrapper--a3772";
export var headerShadow = "Header-module--header--shadow--eeef0";
export var headerSwitchMenu = "Header-module--header-switch-menu--12121";
export var header__logo = "Header-module--header__logo--8be59";
export var header__menuIcon = "Header-module--header__menu-icon--41eab";
export var header__menuIconBtn = "Header-module--header__menu-icon-btn--3f759";
export var mobileMenu = "Header-module--mobile-menu--85e7f";
export var mobileMenu__list = "Header-module--mobile-menu__list--b157f";
export var mobileMenu__listItem = "Header-module--mobile-menu__list-item--e4f8b";
export var mobileMenu__listItemLink = "Header-module--mobile-menu__list-item-link--c241c";
export var mobileMenu__text = "Header-module--mobile-menu__text--0d81b";
export var mobileMenu__wrapper = "Header-module--mobile-menu__wrapper--b4949";