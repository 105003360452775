import React, { ReactElement, ReactNode } from 'react';

import { SlotComponentProps } from './useSlot.types';

export function useSlot(
	type: React.FC<SlotComponentProps>,
	children: ReactNode | ReactNode[],
): ReactElement | ReactElement[] | null {
	const slots = React.Children.toArray(children) as ReactElement[];
	const slot = slots.find((slot) => slot && slot.type === type);

	const slotChildren = slot?.props?.children;

	return slotChildren ?? null;
}
