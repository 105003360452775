import { Autorenew } from '@mui/icons-material';
import { clsx } from 'clsx';
import React, { FC, ReactElement } from 'react';

import * as dateUpdatedStyles from './DateUpdated.module.scss';
import { DateUpdatedProps } from './DateUpdated.types';

export const DateUpdated: FC<DateUpdatedProps> = ({
	date,
	className,
}): ReactElement => {
	const dateTimeFormatter = new Intl.DateTimeFormat('nl-BE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	return (
		<div
			className={clsx(dateUpdatedStyles.dateUpdated, className)}
			data-testid={'date-updated'}>
			<Autorenew className={dateUpdatedStyles.dateUpdatedIcon} />
			<span className={dateUpdatedStyles.dateUpdatedText}>
				Laatste update {dateTimeFormatter.format(date)}
			</span>
		</div>
	);
};
